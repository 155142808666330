<template>
<div>
    <columns>
        <column>
            <google-address classes="is-medium is-rounded" :value="location.address.street"
                @input="setAddress">
                Search for Address
            </google-address>
        </column>
    </columns>
    <columns>
        <column>
            <text-input classes="is-medium is-rounded" :value="address.street" @input="updateStreet"
                :error="$root.errors['address.street']">
                Street
            </text-input>
        </column>
        <column>
            <text-input
                classes="is-medium is-rounded"
                :value="address.suburb"
                @input="updateSuburb"
                :error="$root.errors['address.suburb']">
                Suburb
            </text-input>
        </column>
        <column>
            <text-input
                classes="is-medium is-rounded"
                :value="address.city"
                @input="updateCity"
                :error="$root.errors['address.city']">
                City
            </text-input>
        </column>
        <column>
            <text-input
                classes="is-medium is-rounded"
                :value="address.province"
                @input="updateProvince"
                :error="$root.errors['address.province']">
                State/Province
            </text-input>
        </column>
    </columns>   
    <columns>
        <column class="is-narrow">
            <data-selector
                v-if="!loading"
                searchable
                required
                classes="is-medium is-rounded"
                :items="countries"
                value-key="id"
                label-key="name"
                :value="address.country_id"
                @input="updateCountry"
                :error="$root.errors['address.country_id'] || $root.errors['location.address.country_id']">
                Country
            </data-selector>
        </column>
        <column class="is-narrow">
            <text-input
                classes="is-medium is-rounded"
                :value="address.lat"
                @input="updateLatitude"
                :error="$root.errors['address.lat']">
                Latitude
            </text-input>
        </column>
        <column class="is-narrow">
            <text-input
                classes="is-medium is-rounded"
                :value="address.lng"
                @input="updateLongitude"
                :error="$root.errors['address.lng']">
                Longitude
            </text-input>
        </column>
    </columns> 
</div>  
</template>
<script>
import { country } from '@/api'
import { mapMutations, mapGetters } from 'vuex'
import find from 'lodash/find'

export default {

    data: () => ({
        loading: true,
        countries: [],
        raw_text: ''
    }),

    beforeCreate() {
        country.loadList(({data}) => {
            this.countries = data
            this.loading = false
        }, () => this.loading = false)
    },

    beforeDestroy() {
        this.clearAddress()
    },

    methods: {
        setAddress(address) {
            this.updateLocationName(address.street)
            this.updateAddress(address)
            const country = find(this.countries, country => country.name === address.country)
            if(country) {
                this.updateCountry(country.id)
            }
        },
        ...mapMutations('location', [
            'updateStreet',
            'updateSuburb',
            'updateCity',
            'updateProvince',
            'updateCountry',
            'updateLongitude',
            'updateLatitude',
            'updateLocationName',
            'updateAddress',
            'clearAddress'
        ])
    },

    computed: {
        ...mapGetters('location', [
            'location'
        ]),
        address() {
            return this.location.address
        }
    }

}
</script>